*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'poppins', sans-serif;
}

:root{
  --black:#333;
  --white:#fff;
}

#container-video-vide{
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
  min-height:400px;
  width:100%;
}
#video-not-found{
   background-image: url('../public/images/not-found.jpeg');
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   height:400px;
   width: 400px;
   margin:auto;
}
#global-container{
  min-height:800px;
  overflow: hidden;
}
.text1{
  color: rgb(52, 51, 51);
}
.error404{
   font-size:4em;

}
header{
  position: relative;
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 0px 15px;
  width: 100%;
  z-index: 10000;
  /* box-shadow: 8px 1px 3px 0px gray; */
}
#logo{
  position: relative;
  display:block;
  width: 50px;
  height: 50px;
}
#listcat{
  width:auto;
  display: block;
  text-align: center;
  display: flex;
  align-items: center;
  z-index:999;
  gap: 15px;
}
#listcat ul{
  padding:0;
  margin: 0;
}
.menu
{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-menu{
  position: relative;
  width: 50px;
  height: 50px;
  margin-left: auto;
}
.no_derline{
  text-decoration: none;
}


#logo-img{
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('../public/images/tootiks.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.main{
  position: relative;
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit:cover;
}

.mask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  z-index: 2;
  user-select: none;
}

.main h2{
  position: relative;
  z-index: 3;
  font-size: 16vw;
  text-align: center;
  color: #fff;
  text-shadow: 0 20px 30px rgba(0,0, 0, 2);
  opacity: 0.25;
}

.lop
{
  position: absolute;
  z-index: 4;
  right: 30px;
  top: 60px;
  z-index: 4;
  display: flex;
  flex-direction: column;
}

.lop li
{
  list-style: none;
}

.lop li a
{
  text-decoration: none;
  color: black;
  font-size: 25px;
}

.menu li
{
  position: absolute;
  list-style: none;
  transform-origin: 100px;
  left: -90px;
  top: -20px;
  transition: 0.5s;
  transition-delay: calc(0.1s * var(--i));
  transform: rotate(0deg)translateX(80px);
  opacity: 0;
}
.active li{
  opacity: 1;
}

.menu li a
{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform: rotate(calc(180deg / -5 * var(--i)));
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0,15);
  color: #111;
  transition: 0.5s;
}

.menu li a:hover
{
  color: #ff1252;
}

.menu.active li
{
  transform: rotate(calc(-80deg/5*var(--i)));
}

.toggle
{
  position: absolute;
  width: 40px;
  height: 40px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0,16);
  font-size: 2em;
  transition: transform 1.25s;
}

.menu.active .toggle
{
  transform: rotate(360deg);
}

#molopo li{
  list-style: none;
  display: inline;
  position: relative;
  width: 100%;
  
}

#molopo a, .header-login a,.header-login {
  color: black;
  text-decoration: none;
  letter-spacing: 3px;
  font-size: 1.5rem;
  text-transform: uppercase;
  justify-content: space-around;
  align-items: center;
  font-weight: 550;
}
.header-login a,.header-login span{
  color:#5abff1;
  cursor: pointer;
  display: none;
}

.container{
  margin-top: 110px;
}
#loading{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:100%;
  position: absolute;
  z-index:99;
}
#loading-item{
   margin:auto;
   color: #9eefe7;
   font-size:34px;
}
.card{
  border : none;
  outline: none;
  background-color: white;
  border-radius: 40px;
  transition: transform.3s;
}

.card:hover{
  transform: translateY(-15px);
  transition: transform.3s;
}

.test1{
  font-size: 13px;
  color: #cbcbcb;
}

.info{
  line-height: 17px;
}

.star{
  color:#fbc02d;
  /* display: flex; */
  /* justify-content: start; */
}




.photo1{
  background: #fb3531;
  color: #fff;
}

.bissap1{
  background-color: red;
  color: #fff;
  font-weight: bolder;
  text-decoration: none;
}

.bissap2{
  background-color: rgb(0, 255, 234);
  color: #fff;
}

.bissap3{
  background-color: rgb(255, 251, 0);
  color: #fff;
}

.footer-top{
  background-color: black;
}

.botom{
  margin-top: 8px;
  text-align: center;
 
}

.box-first{
  box-shadow: 1px 1px 5px ;
  border-radius: 5px;
}

/* Admin page*/
.admin{
  width:100%;
  position:relative;
}
.admin h1{
  text-align: center;
  width:100%;
  position: relative;
}
.container-admin{
  display: flex;
  width:100%;
  /* background: rgb(253, 250, 250); */
  height:100%;
  flex-wrap:wrap;
  position: relative;
}
.container-admin a{
  display: block;
  width:95%;
  padding:5px;
  float:right;
  font-size:1.1rem;
  color:black;
  text-decoration: none;
  transition: all 0.5s;
}
.container-admin a:hover{
  background-color:rgb(235, 235, 234);
}
.container-admin .left-container{
   width:20%;
   height:200px;
   background-color: rgb(248, 248, 248);
   position: relative;
}
.container-admin .right-container{
   width:80%;
   min-height:900px;
   position: relative;
}
/* form  */
.form-data{
   width:100%;
   display: flex;
   justify-content: center;
   justify-items: start;
   position: relative;
}
.form-data .error{
  background: #f47573;
  padding:4px;
  border-radius:8px;
}
.form-data .success{
  background: #6be0d5;
  padding:4px;
  border-radius:8px;
}
.form-data form{
  background:white ;
  width:450px;
  display: flex;
  justify-content: center;
  justify-items:center;
  position: relative;
  padding:8px;
  margin-top:20px;
  padding-bottom:18px;
  flex-wrap: wrap;
  border-radius:8px;
  box-shadow:5px 10px 8px 10px #d6d5d5;
}
/* .form-data label{
  position: relative;
  display: block;
  margin-bottom:2px;
  width:400px;
} */
.form-data input{
  position: relative;
  display: inline-block;
  width:100%;
  margin:auto;
  padding:2px;
  height:35px;
  border-radius:5px;
}
.form-data select{
  position: relative;
  display: inline-block;
  width:100%;
  margin:auto;
  padding:2px;
  height:35px;
  text-transform: capitalize;
  border-radius:5px;
}
.form-data textarea{
  position: relative;
  display: inline-block;
  width:400px;
  padding:2px;
  text-transform: capitalize;
  border-radius:5px;
}
.form-data .btn{
  width:400px;
  padding:2px;
  padding-top:10px;
  text-align: start;
}
.form-data  button{
  background:rgb(65, 169, 190);
  padding:4px 8px;
  outline:none;
  border-radius:8px;
  width:100%;
}
.form-data  button:hover{
  background:rgb(35, 127, 146);
  color: white;
}
/* list data container */
.list-data-container{
    width:100%;
    padding:8px;
}
.login .item-form{
  padding-bottom:12px;
  padding-top:12px;
}
.login  button{
  margin-top:8px;
}
.list-data-container table{
   width:98%;
   margin:auto;
   background-color: #fff;
   border-radius: 8px;
   padding:2px;
}
.list-data-container tr,td,th{
    border:1px solid black;
    padding:4px;
    text-align: center;
    color: black;
}
.list-data-container tr::first-letter,td::first-letter,th::first-letter{
   text-transform: capitalize;
}
.admin-bg-url{
   background-image: url('/public/images/p-1.jpg');
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   width:100%;
   height:100%;
   position: relative;
}
.list-data-container table button{
   outline: none;
   padding:2px 7px;
   border:none;
   background: rgb(247, 126, 126);
   transition: all 0.6s;
   border-radius:8px;
   margin:4px;
   text-transform: capitalize;
}
.ajouter-article{
   width:98%;
   position: relative;
}
.ajouter-article a{
   width:100px;
   padding:4px 8px;
   margin-bottom:8px;
   border-radius:8px;
   text-align: center;
   background: rgb(64, 141, 145);
   color: white;
   float: right;
   text-decoration: none;
}
.list-data-container table .modif{
   background-color: #9eefe7;
}
.list-data-container table .modif:hover{
  background-color: #5de4d7;
}

.list-data-container table button:hover{
  background: rgb(156, 53, 53);
}
.image-admin-habit{
  width:60px;
  height:40px;
  border-radius:8px;
  margin-right:8px;
  object-fit: cover;
}
.btn_habit{
  display: block;
  width:100%;
}
.td-title{
  text-align: start;
}

td .title{
  cursor:pointer;
}
td .title:hover{
   color: #5abff1;
   text-decoration:underline;
}
/* .img_produit_item{
   background-position: center;
   background-size: cover;
   height:200px;
   width:160px;
   background-repeat: no-repeat;
   margin: auto;
} */
.item-form{
  width:95%;
  margin:auto;
  flex-wrap:wrap;
  display:flex;
  justify-content:center;
}
.form h2{
  text-align:center;
}
.form p{
  width:100%;
  display:block;
  text-align: center;
}
.label{
  display: block;
  width: 100%;
  margin-top: 20px;
}
.page-center{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
@media only screen and (max-width: 768px) {
  #molopo a, .header-login a,.header-login {
    font-size: 0.8em;
  }
  /* .main{
    min-height: 80vh;
  } */
  .lop{
    top:50px;
  }
  .form-data .form{
    top:15px;
    width:90%;
    margin:auto;
  }
  .global-container{
     min-height:450px;
  }
  header{
    flex-wrap: wrap;
  }
  .container-admin .right-container{
    width: 100%;
  }
  .container-admin .left-container{
     width: 100%;
     height: auto;
     display: flex;
     text-align: center;
     justify-content: space-between;
     align-items: center;
  }
}

.product-1 .img-container{
    width: 100%;
    position: relative;
    height: 200px;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;
}
.img{
   object-fit: cover;
   width: 100%;
   height: 100%;
   position: relative;
}
.detail .container-img{
    width: 100%;
    position: relative;
    height: 300px;
    overflow: hidden;
    border-radius: 5px;
}